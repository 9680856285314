import React from "react";

function Sidebar() {
    return (
        <>
            <div className="app-menu navbar-menu">
                <div className="navbar-brand-box">
                    <a href="/csadmin/dashboard" className="logo logo-dark">
                        <span className="logo-sm">
                            <img src="/img/logo.png" alt="" height="22"/>
                        </span>
                        <span className="logo-lg">
                            <img src="/img/logo.png" alt="" height="17"/>
                        </span>
                    </a>
                
                    <a href="/csadmin/dashboard" className="logo logo-light">
                        <span className="logo-sm">
                            <img src="assets/images/logo-sm.png" alt="" height="22"/>
                        </span>
                        <span className="logo-lg">
                            <img src="assets/images/logo-light.png" alt="" height="17"/>
                        </span>
                    </a>
                    <button type="button" className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover" id="vertical-hover">
                        <i className="ri-record-circle-line"></i>
                    </button>
                </div>

                <div id="scrollbar">
                    <div className="container-fluid">
                        <div id="two-column-menu"></div>
                        <ul className="navbar-nav" id="navbar-nav">
                            <li className="menu-title"><span data-key="t-menu">Menu</span></li>
                            <li className="nav-item">
                                <a className="nav-link menu-link" href="/csadmin/dashboard">
                                    <i className="ri-honour-line"></i> <span data-key="dashboard">Dashboard</span>
                                </a>
                            </li>
                          
                            <li className="nav-item">
                                <a className="nav-link menu-link" href="#newsBlogs" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="newsBlogs">
                                    <i className="ri-file-list-3-line"></i> <span data-key="news-blogs">News & Blogs</span>
                                </a>
                                <div className="collapse menu-dropdown" id="newsBlogs">
                                    <ul className="nav nav-sm flex-column">
                                        <li className="nav-item">
                                            <a href="/csadmin/news-blogs" className="nav-link" data-key="allnewsblogs"> All News & Blogs </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="/csadmin/add-new-blogs" className="nav-link" data-key="addnewsblogs">Add New Blogs</a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="/csadmin/news-blogs/categories" className="nav-link" data-key="categories">Categories</a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="/csadmin/news-blogs/tags" className="nav-link" data-key="tags">Tags</a>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link menu-link" href="#appearance" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="appearance">
                                    <i className="ri-layout-masonry-line"></i> <span data-key="appearance">Appearance</span>
                                </a>
                                <div className="collapse menu-dropdown" id="appearance">
                                    <ul className="nav nav-sm flex-column">
                                        <li className="nav-item">
                                            <a href="/csadmin/appearance/footer" className="nav-link" data-key="footer"> Footer </a>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link menu-link" href="/csadmin/customer">
                                <i class="ri-user-line"></i> <span data-key="t-widgets">Customers</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link menu-link" href="/csadmin/newsletter">
                                    <i className="ri-mail-unread-line"></i> <span data-key="t-widgets">News Letter</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link menu-link" href="/csadmin/add-new-faq">
                                    <i className="ri-question-answer-line"></i> <span data-key="t-widgets">FAQ</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link menu-link" href="/csadmin/currency">
                                    <i className="ri-question-answer-line"></i> <span data-key="t-widgets">Currency</span>
                                </a>
                            </li>
                           
                            <li className="nav-item">
                                <a className="nav-link menu-link" href="#pagessection" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="pages">
                                <i class="ri-article-line"></i><span data-key="pages">Pages</span>
                                </a>
                                <div className="collapse menu-dropdown" id="pagessection">
                                    <ul className="nav nav-sm flex-column">
                                        <li className="nav-item">
                                            <a href="/csadmin/all-pages" className="nav-link" data-key="allpages"> All Pages </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="/csadmin/add-new-pages" className="nav-link" data-key="addnewpages">Add New Pages</a>
                                        </li>
                                       
                                    </ul>
                                </div>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link menu-link" href="#settings" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="settings">
                                    <i className="ri-dashboard-2-line"></i> <span data-key="Settings">Settings</span>
                                </a>
                                <div className="collapse menu-dropdown" id="settings">
                                    <ul className="nav nav-sm flex-column">
                                        <li className="nav-item">
                                            <a href="/csadmin/defaultvalue" className="nav-link" data-key="t-analytics"> Default Value </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="/csadmin/defaultvalueyearwise" className="nav-link" data-key="t-analytics"> Default Value Year wise </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="/csadmin/general-settings" className="nav-link" data-key="t-analytics"> General Setting </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="/csadmin/social-settings" className="nav-link" data-key="t-analytics"> Social Setting </a>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="sidebar-background"></div>
            </div>
        </>
    );
}

export default Sidebar;
