import React, { useState, useEffect, useRef } from "react";
import AdminHeader from "../../component/Header";
import Sidebar from "../../component/Sidebar";
import { useForm, SubmitHandler, useWatch } from "react-hook-form"
import { ApiService } from "../../component/Services/apiservices";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";

function DefaultValue() {
    let apiServices = new ApiService();
    const [defaultvalueid, setdefaultvalueid] = useState()
    const didMountRef = useRef(true)
    const Navigate = useNavigate()
    const {
        register,
        handleSubmit,
        watch,
        setError,
        getValues,
        setValue,
        formState: { errors }
    } = useForm();

    const onSubmit = (data) => {

        const datastring = {
            dfterm_variable: data.termvariable,
            dfterm_fixed: data.termfixed,
            dfinterest_fixed:data.interestfixed,
            dfinterest_variable:data.interestvariable,
            dfelectricalinspectionfee: data?.electricalinspectionfee,
            dfloan_type: data.loantype,
            dfother_agent_fee: data.otheragentfee,
            dfbuyer_agent_fee: data.buyersagentfee,
            dfother_rebate: data.otherbate,
            dfpurchase_config: data?.purchaseconfig,
            dfpest_insp_cost: data?.timberinspectionfee,
            dfplumbing_inst_cost: data?.plumbinginspectionfee,
            dfany_other_fee: data?.anyotherfee,
            dfstructural_insp_cost: data?.structuralinspectionfee,
            dfbuilding_ins_cost: data?.buildinginsaurencefee,
            dffhog_home_owner: data?.firsthomeowner,
            dfnoof_unit: data?.noofunits,
            dffhog_amount: data?.fhogamount,
            dfland_regis: data?.landgateregistrat,
            dfmaintaince_allownce: data?.maintainceallowance,
            dfsettlement_agent_fee: data?.settlementagentfee,
            dfbank_mortage_setup: data?.bankmortgagesetupfee,
            dfpexa_fee: data?.pexafeepaid,
            // dfmaintain_loan_fixedintrest_per:data?.loanfixedinterestpercent,
            dfmaintainance_Council_rates: data?.council_rates,
            dfmaintain_maintaince_allownce: data?.maintain_maintaince_allownce,
            dfinflation: data?.inflation,
            dfmaintainance_building_insuar: data?.maintainance_building_insuar,
            dfmaintainance_water_rates: data?.water_rates,
            dfowner_occupied_week: data?.owner_occupied_week,
            dfvacant_week: data?.vacant_feeweeks,
            dfgrowth_assump: data?.growth_assump,
            dfrental_growth: data?.rental_growth,
            // dfmaintain_loan_variableintrest_per:data?.loanvariableintrestper,
            dfland_tax: data?.landTax,
            dfletting_fee_weeks: data?.letting_feeweeks,
            dfproperty_manag_perc: data?.property_manag_perc,
            dfprincipal_pay: data?.principal_pay,
            dfrenovation_uplift_cost: data?.renovation_uplift_cost,
            dfsubdivision_uplift_cost: data?.subdivision_uplift_cost,
            dfgrannyfelt_uplift_cost: data?.grannyfelt_uplift_cost,
            defaultvalue_id: defaultvalueid
        }
        apiServices.getdashboarddefaultvalues(datastring).then(res => {
            if (res.data.status == "success") {
                toast.success(res?.data?.message)
                // setTimeout(()=>{
                //     Navigate("/csadmin/dashboard")
                // },2000)
            }

        })
    }
    useEffect(() => {

        if (didMountRef.current) {

            apiServices.getdefaultvaluesrequest().then(res => {
                if (res?.data?.status == "success") {
                    //   setdefaultValues(res?.data?.defaultvalues)
                    if (res.data.status == "success") {
                        if (res?.data?.defaultvalues !== null) {
                            setdefaultvalueid(res?.data?.defaultvalues?._id)
                            setValue("purchaseconfig", res?.data?.defaultvalues?.dfpurchase_config);
                            setValue("interestvariable", res?.data?.defaultvalues?.dfinterest_variable);
                            setValue("interestfixed", res?.data?.defaultvalues?.dfinterest_fixed);
                            setValue("otherbate", res?.data?.defaultvalues?.dfother_rebate);
                            setValue("buyersagentfee", res?.data?.defaultvalues?.dfbuyer_agent_fee);
                            setValue("otheragentfee", res?.data?.defaultvalues?.dfother_agent_fee);
                            setValue("loantype", res?.data?.defaultvalues?.dfloan_type);
                            setValue("termfixed", res?.data?.defaultvalues?.dfterm_fixed);
                            setValue("termvariable", res?.data?.defaultvalues?.dfterm_variable);
                            setValue("noofunits", res?.data?.defaultvalues?.dfnoof_unit);
                            setValue("fhogamount", res.data.defaultvalues?.dffhog_amount);
                            setValue("firsthomeowner", res.data.defaultvalues?.dffhog_home_owner);
                            setValue("structuralinspectionfee", res.data.defaultvalues?.dfstructural_insp_cost);
                            setValue("buildinginsaurencefee", res.data.defaultvalues?.dfbuilding_ins_cost);
                            setValue("timberinspectionfee", res.data.defaultvalues?.dfpest_insp_cost);
                            setValue("anyotherfee", res.data.defaultvalues?.dfany_other_fee);
                            setValue("plumbinginspectionfee", res.data.defaultvalues?.dfplumbing_inst_cost);
                            setValue("electricalinspectionfee", res.data.defaultvalues?.dfelectricalinspectionfee);
                            setValue("settlementagentfee", res.data.defaultvalues?.dfsettlement_agent_fee);
                            setValue("pexafeepaid", res.data.defaultvalues?.dfpexa_fee);
                            setValue("bankmortgagesetupfee", res.data.defaultvalues?.dfbank_mortage_setup);
                            setValue("landgateregistrat", res.data?.defaultvalues?.dfland_regis);
                            setValue("maintainceallowance", res.data?.defaultvalues?.dfmaintaince_allownce);
                            setValue("landTax", res.data?.defaultvalues?.dfland_tax);
                            setValue("letting_feeweeks", res.data?.defaultvalues?.dfletting_fee_weeks);
                            setValue("vacant_feeweeks", res.data?.defaultvalues?.dfvacant_week);
                            setValue("owner_occupied_week", res.data?.defaultvalues?.dfowner_occupied_week);
                            // setValue("loanvariableintrestper", res.data?.defaultvalues?.dfmaintain_loan_variableintrest_per);
                            // setValue("loanfixedinterestpercent", res.data?.defaultvalues?.dfmaintain_loan_fixedintrest_per);
                            setValue("inflation", res.data?.defaultvalues?.dfinflation);
                            setValue("growth_assump", res.data?.defaultvalues?.dfgrowth_assump);
                            setValue("rental_growth", res.data?.defaultvalues?.dfrental_growth);
                            setValue("principal_pay", res.data?.defaultvalues?.dfprincipal_pay);
                            setValue("property_manag_perc", res.data?.defaultvalues?.dfproperty_manag_perc);
                            setValue("council_rates", res.data?.defaultvalues?.dfmaintainance_Council_rates);
                            setValue("maintainance_building_insuar", res.data?.defaultvalues?.dfmaintainance_building_insuar);
                            setValue("water_rates", res.data?.defaultvalues?.dfmaintainance_water_rates);
                            setValue("maintain_maintaince_allownce", res.data?.defaultvalues?.dfmaintain_maintaince_allownce);
                            // 
                            setValue("renovation_uplift_cost", res.data?.defaultvalues?.dfrenovation_uplift_cost);
                            setValue("subdivision_uplift_cost", res.data?.defaultvalues?.dfsubdivision_uplift_cost);
                            setValue("grannyfelt_uplift_cost", res.data?.defaultvalues?.dfgrannyfelt_uplift_cost);
                           

                        }
                    }
                }
            })
        }
        didMountRef.current = false;

    })

    return (
        <>
            <ToastContainer />
            <div id="layout-wrapper">

                <AdminHeader />
                <Sidebar />

                <div class="main-content">

                    <div class="page-content">
                        <div class="container-fluid">


                            <div class="row">
                                <div class="col-12">
                                    <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 class="mb-sm-0">Default Value</h4>

                                        <div class="page-title-right">
                                            <ol class="breadcrumb m-0">
                                                <li class="breadcrumb-item"><a href="/csadmin/dashboard">Dashboard</a></li>
                                                <li class="breadcrumb-item active">Default Value</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="card">
                                        <div class="card-header align-items-center d-flex">
                                            <h4 class="card-title mb-0 flex-grow-1">Values</h4>
                                          
                                        </div>
                                        <div class="card-body">
                                            <div class="live-preview">
                                                <form onSubmit={handleSubmit(onSubmit)}>
                                                    <div class="row gy-4">
                                                        <div class="col-xxl-3 col-md-6">
                                                            <div>
                                                                <label for="basiInput" class="form-label">Purchase Configuration</label>
                                                                <select class="form-control" name="purchaseconfig" {...register("purchaseconfig")} >
                                                                    <option value=""> Select Purchase Configuration</option>
                                                                    <option value="5*3">5*3</option>
                                                                    <option value="5*2">5*2</option>
                                                                    <option value="4*3">4*3</option>
                                                                    <option value="4*2">4*2</option>
                                                                    <option value="4*1">4*1</option>
                                                                    <option value="3*2">3*2</option>
                                                                    <option value="3*1">3*1</option>
                                                                    <option value="2*1">2*1</option>
                                                                    <option value="1*1">1*1</option>
                                                                    <option value="custom">Custom</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-xxl-3 col-md-6">
                                                            <div>
                                                                <label for="basiInput" class="form-label">Other Rebate</label>
                                                                <input type="number" class="form-control" name="otherbate"  {...register("otherbate")} step="any" />
                                                            </div>
                                                        </div>
                                                        <div class="col-xxl-3 col-md-6">
                                                            <div>
                                                                <label for="basiInput" class="form-label">Buyers Agent Fee</label>
                                                                <input type="number" class="form-control" name="buyersagentfee"  {...register("buyersagentfee")} step="any" />
                                                            </div>
                                                        </div>
                                                        <div class="col-xxl-3 col-md-6">
                                                            <div>
                                                                <label for="basiInput" class="form-label">Other Agent Fee</label>
                                                                <input type="number" class="form-control" name="otheragentfee"   {...register("otheragentfee")} step="any" />
                                                            </div>
                                                        </div>
                                                        <div class="col-xxl-3 col-md-6">
                                                            <div>
                                                                <label for="basiInput" class="form-label">Select Loan Type</label>
                                                                <select class="form-control" name="loantype"  {...register("loantype")}   >
                                                                    <option value="P&I">P&I</option>
                                                                    <option value="Interest">Interest</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-xxl-3 col-md-6">
                                                            <div>
                                                                <label for="basiInput" class="form-label">Interest Fixed</label>
                                                                <input type="number" class="form-control" name="interestfixed"   {...register("interestfixed")} step="any" />
                                                            </div>
                                                        </div>
                                                        <div class="col-xxl-3 col-md-6">
                                                            <div>
                                                                <label for="basiInput" class="form-label">Interest Variable</label>
                                                                <input type="number" class="form-control" name="interestvariable"   {...register("interestvariable")} step="any" />
                                                            </div>
                                                        </div>
                                                        <div class="col-xxl-3 col-md-6">
                                                            <div>
                                                                <label for="basiInput" class="form-label">Term Fixed</label>
                                                                <input type="number" class="form-control" name="termfixed"   {...register("termfixed")} step="any" />
                                                            </div>
                                                        </div>
                                                        <div class="col-xxl-3 col-md-6">
                                                            <div>
                                                                <label for="basiInput" class="form-label">Term Variable</label>
                                                                <input type="number" class="form-control" name="termvariable"  {...register("termvariable")} step="any" />
                                                            </div>
                                                        </div>
                                                        <div class="col-xxl-3 col-md-6">
                                                            <div>
                                                                <label for="basiInput" class="form-label">Noof Units</label>
                                                                <input type="number" class="form-control" name="noofunits"   {...register("noofunits")} step="any" />
                                                            </div>
                                                        </div>

                                                        <div class="col-xxl-3 col-md-6">
                                                            <div>
                                                                <label for="basiInput" class="form-label"> FHOG amount</label>
                                                                <input type="number" class="form-control" name="fhogamount"  {...register("fhogamount")} step="any" />
                                                            </div>
                                                        </div>
                                                        <div class="col-xxl-3 col-md-6">
                                                            <div>
                                                                <label for="basiInput" class="form-label">First Home Owner</label>
                                                                <select class="form-control" name="firsthomeowner"    {...register("firsthomeowner")}   >
                                                                    <option value="yes">Yes</option>
                                                                    <option value="no">No</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div class="col-xxl-3 col-md-6">
                                                            <div>
                                                                <label for="basiInput" class="form-label">Building insurance cost</label>
                                                                <input type="number" class="form-control" name="buildinginsaurencefee" {...register("buildinginsaurencefee")} step="any" />
                                                            </div>
                                                        </div>


                                                        <div class="col-xxl-3 col-md-6">
                                                            <div>
                                                                <label for="basiInput" class="form-label">Structural inspection cost</label>
                                                                <input type="number" class="form-control" name="structuralinspectionfee" id="basiInput"  {...register("structuralinspectionfee")} step="any" />
                                                            </div>
                                                        </div>




                                                        <div class="col-xxl-3 col-md-6">
                                                            <div>
                                                                <label for="basiInput" class="form-label">pest inspection cost</label>
                                                                <input type="number" class="form-control" name="timberinspectionfee"  {...register("timberinspectionfee")} step="any" />
                                                            </div>
                                                        </div>

                                                        <div class="col-xxl-3 col-md-6">
                                                            <div>
                                                                <label for="basiInput" class="form-label">Plumbing inspection cost</label>
                                                                <input type="number" class="form-control" name="plumbinginspectionfee" {...register("plumbinginspectionfee")} step="any" />
                                                            </div>
                                                        </div>

                                                        <div class="col-xxl-3 col-md-6">
                                                            <div>
                                                                <label for="basiInput" class="form-label">Electrical inspection cost</label>
                                                                <input type="number" class="form-control" name="electricalinspectionfee"  {...register("electricalinspectionfee")} step="any" />
                                                            </div>
                                                        </div>

                                                        <div class="col-xxl-3 col-md-6">
                                                            <div>
                                                                <label for="basiInput" class="form-label">Settlement Agent fee</label>
                                                                <input type="number" class="form-control" name="settlementagentfee" {...register("settlementagentfee")} step="any" />
                                                            </div>
                                                        </div>
                                                        <div class="col-xxl-3 col-md-6">
                                                            <div>
                                                                <label for="basiInput" class="form-label">Any Other fee</label>
                                                                <input type="number" class="form-control" name="anyotherfee" {...register("anyotherfee")} step="any" />
                                                            </div>
                                                        </div>

                                                        <div class="col-xxl-3 col-md-6">
                                                            <div>
                                                                <label for="basiInput" class="form-label">Bank Mortgage setup fee</label>
                                                                <input type="number" class="form-control" name="bankmortgagesetupfee"  {...register("bankmortgagesetupfee")} step="any" />
                                                            </div>
                                                        </div>


                                                        <div class="col-xxl-3 col-md-6">
                                                            <div>
                                                                <label for="basiInput" class="form-label">Pexa fee</label>
                                                                <input type="number" class="form-control" name="pexafeepaid" {...register("pexafeepaid")} step="any" />
                                                            </div>
                                                        </div>


                                                        <div class="col-xxl-3 col-md-6">
                                                            <div>
                                                                <label for="basiInput" class="form-label">Land Gate Registration</label>
                                                                <input type="number" class="form-control" name="landgateregistrat"  {...register("landgateregistrat")} step="any" />
                                                            </div>
                                                        </div>
                                                        <div class="col-xxl-3 col-md-6">
                                                            <div>
                                                                <label for="basiInput" class="form-label" > Purchase Maintaince Allowance</label>
                                                                <input type="number" class="form-control" name="maintainceallowance" {...register("maintainceallowance")} step="any" />
                                                            </div>
                                                        </div>

                                                        {/* <div class="col-xxl-3 col-md-6">
                                                            <div>
                                                                <label for="basiInput" class="form-label">Loan Fixed Interest Percent</label>
                                                                <input type="number" class="form-control" name="loanfixedinterestpercent" {...register("loanfixedinterestpercent")}  step="any" />
                                                            </div>
                                                        </div>
                                                        <div class="col-xxl-3 col-md-6">
                                                            <div>
                                                                <label for="basiInput" class="form-label">Loan variable Interest Percent</label>
                                                                <input type="number" class="form-control" name="loanvariableintrestper" {...register("loanvariableintrestper")}  step="any" />
                                                            </div>
                                                        </div> */}
                                                        <div class="col-xxl-3 col-md-6">
                                                            <div>
                                                                <label for="basiInput" class="form-label">Weeks ocuupied by owner</label>
                                                                <input type="number" class="form-control" name="owner_occupied_week" {...register("owner_occupied_week")} />
                                                            </div>
                                                        </div>
                                                        <div class="col-xxl-3 col-md-6">
                                                            <div>
                                                                <label for="basiInput" class="form-label">Vacant Fee weeks</label>
                                                                <input type="number" class="form-control" name="vacant_feeweeks" {...register("vacant_feeweeks")} />
                                                            </div>
                                                        </div>
                                                        <div class="col-xxl-3 col-md-6">
                                                            <div>
                                                                <label for="basiInput" class="form-label">Letting Fee Weeks</label>
                                                                <input type="number" class="form-control" name="letting_feeweeks" {...register("letting_feeweeks")} />
                                                            </div>
                                                        </div>
                                                        <div class="col-xxl-3 col-md-6">
                                                            <div>
                                                                <label for="basiInput" class="form-label">Land Tax</label>
                                                                <input type="number" class="form-control" name="landTax" {...register("landTax")} step="any" />
                                                            </div>
                                                        </div>
                                                        <div class="col-xxl-3 col-md-6">
                                                            <div>
                                                                <label for="basiInput" class="form-label">Principal Pay</label>
                                                                <input type="number" class="form-control" name="principal_pay" {...register("principal_pay")} step="any" />
                                                            </div>
                                                        </div>
                                                        <div class="col-xxl-3 col-md-6">
                                                            <div>
                                                                <label for="basiInput" class="form-label">Propert Managment Percent</label>
                                                                <input type="number" class="form-control" name="property_manag_perc" {...register("property_manag_perc")} step="any" />
                                                            </div>
                                                        </div>
                                                        <div class="col-xxl-3 col-md-6">
                                                            <div>
                                                                <label for="basiInput" class="form-label">inflation</label>
                                                                <input type="number" class="form-control" name="inflation" {...register("inflation")} step="any" />
                                                            </div>
                                                        </div>
                                                        <div class="col-xxl-3 col-md-6">
                                                            <div>
                                                                <label for="basiInput" class="form-label">Rental Growth</label>
                                                                <input type="number" class="form-control" name="rental_growth" {...register("rental_growth")} step="any" />
                                                            </div>
                                                        </div>
                                                        <div class="col-xxl-3 col-md-6">
                                                            <div>
                                                                <label for="basiInput" class="form-label">Growth Assumption</label>
                                                                <input type="number" class="form-control" name="growth_assump" {...register("growth_assump")} step="any" />
                                                            </div>
                                                        </div>
                                                        <div class="col-xxl-3 col-md-6">
                                                            <div>
                                                                <label for="basiInput" class="form-label">Maintainance Building Insuareance</label>
                                                                <input type="number" class="form-control" name="maintainance_building_insuar" {...register("maintainance_building_insuar")} step="any" />
                                                            </div>
                                                        </div>
                                                        <div class="col-xxl-3 col-md-6">
                                                            <div>
                                                                <label for="basiInput" class="form-label">Water Rates</label>
                                                                <input type="number" class="form-control" name="water_rates" {...register("water_rates")} step="any" />
                                                            </div>
                                                        </div>
                                                        <div class="col-xxl-3 col-md-6">
                                                            <div>
                                                                <label for="basiInput" class="form-label">Council Rates</label>
                                                                <input type="number" class="form-control" name="council_rates" {...register("council_rates")} step="any" />
                                                            </div>
                                                        </div>
                                                        <div class="col-xxl-3 col-md-6">
                                                            <div>
                                                                <label for="basiInput" class="form-label">Maintainance Allowance </label>
                                                                <input type="number" class="form-control" name="maintain_maintaince_allownce" {...register("maintain_maintaince_allownce")} step="any" />
                                                            </div>
                                                        </div>
                                                        {/* New feilds */}
                                                  
                                                        <div class="col-lg-12">
                                                            <div class="text-end">
                                                                <button type="submit" class="btn btn-primary">Submit</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>

                                            </div>


                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
export default DefaultValue