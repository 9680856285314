import React, { useState, useEffect, useRef } from "react";
import AdminHeader from "../../component/Header";
import Sidebar from "../../component/Sidebar";
import { useForm, SubmitHandler, useWatch } from "react-hook-form"
import { ApiService } from "../../component/Services/apiservices";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";

function Currency() {
    let apiServices = new ApiService();
    const [currencyId, setCurrencyId]= useState(null)

    const didMountRef = useRef(true)
    const Navigate = useNavigate()
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm();

    const onSubmit = (data) => {
        const datastring = {
            currencyCode: data.currencyCode, 
            _id:currencyId? currencyId:null
        }
        ApiService.postData('/admin/dashboard/savecurrency',datastring).then(res => {
            if (res.status == "success") {
                toast.success(res?.message)
            }
            else{
                toast.error(res.message)
            }
        })
    }
    useEffect(() => {
        if (didMountRef.current) {
            getCurrency() 
        }
        didMountRef.current = false;
    })
    const getCurrency = () => {
        ApiService.fetchData("/admin/dashboard/getcurrency").then((res) => {
            if (res?.status == "success") {
                console.log(res , 'response')
                 setValue("currencyCode", (res?.data)[0]?.currencyCode);
                setCurrencyId((res?.data)[0]?._id)
            }
        }).catch((error) => { })
    };
    
    return (
        <>
            <ToastContainer />
            <div id="layout-wrapper">

                <AdminHeader />
                <Sidebar />

                <div class="main-content">

                    <div class="page-content">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-12">
                                    <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 class="mb-sm-0">Currency</h4>
                                        <div class="page-title-right">
                                            <ol class="breadcrumb m-0">
                                                <li class="breadcrumb-item"><a href="/csadmin/dashboard">Dashboard</a></li>
                                                <li class="breadcrumb-item active">Currency</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="live-preview">
                                                <form onSubmit={handleSubmit(onSubmit)}>
                                                    <div class="row gy-4">
                                                        <div class="col-xxl-12 col-md-6">
                                                            <div>
                                                                <label for="basiInput" class="form-label">Currency Code</label>
                                                                <input class="form-control" name="currencyCode" {...register("currencyCode" , {
                                                                    required: true,

                                                                })} >
                                                                   
                                                                </input>
                                                                {errors.currencyCode && errors.currencyCode.type === "required" && (
                                                                    <small className="text-danger">CurrencyCode is required.</small>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div class="col-xxl-12 col-md-6">
                                                            <div class="text-end">
                                                                <button type="submit" class="btn btn-primary">Submit</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
export default Currency