import AdminFooter from "../../component/Footer"
import AdminHeader from "../../component/Header"
import Sidebar from "../../component/Sidebar"
import { ToastContainer, toast } from 'react-toastify';
import { useForm, SubmitHandler, useWatch } from "react-hook-form"
import { ApiService } from "../../component/Services/apiservices";
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useParams } from "react-router-dom";
import React, { useRef, useState, useEffect } from "react";
import Alert from 'react-bootstrap/Alert';
import constant from "../../component/Services/constant";



const AddNewPages = () => {
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        getValues,
        formState: { errors }
    } = useForm();
    let apiServices = new ApiService();
    const didMountRef = useRef(true)
    const { slug } = useParams()
    const [HeaderImage, setHeaderImage] = useState('')
    const [showtitle, setshowtitle] = useState(0)
    const [showbreadcrumb, setshowbreadcrumb] = useState(0)
    const [pageContent, setpageContent] = useState('')
    const [isEditorReady, setIsEditorReady] = useState(false);
    const [error, seterror] = useState({})
    const editorRef = useRef(null);


    useEffect(() => {

        if (didMountRef.current) {

            if (slug) {

                getPagedetail()
            }
        }
        didMountRef.current = false;

    })
    const onSubmit = (data) => {
        seterror({})
        const formData = new FormData();
        formData.append('page_title', data.page_title);
        formData.append('page_url', data.page_url);
        if (data.page_meta_title && data.page_meta_title.length < 70) {
            formData.append('page_meta_title', data.page_meta_title);
        }
        else if (data.page_meta_title && data.page_meta_title.length > 70) {
            seterror({
                type: "page_meta_title",
                message: "Meta Title should not be greater than 70 characters",
            })
            return
        }
        if (data.page_meta_keyword && data.page_meta_keyword.length < 160) {
            formData.append('page_meta_keyword', data.page_meta_keyword);
        }
        else if (data.page_meta_keyword && data.page_meta_keyword.length > 160) {
            seterror({
                type: "page_meta_keyword",
                message: "Meta Keyword should not be greater than 160 characters",
            })
            return
        }
        if (data.page_meta_description && data.page_meta_description.length < 250) {
            formData.append('page_meta_description', data.page_meta_description);
        }
        else if (data.page_meta_description && data.page_meta_description.length > 250) {
            seterror({
                type: "page_meta_description",
                message: "Meta Description should not be greater than 250 characters",
            })
            return
        }
        formData.append('page_show_breadcrumbs', showbreadcrumb);
        formData.append('page_show_title', showtitle);
        if (pageContent && pageContent !== null && pageContent !== '') {
            formData.append('page_content', pageContent);
        }
        if (HeaderImage && HeaderImage !== '')
            formData.append('page_header_img', HeaderImage);
        if (slug && slug !== null)
            formData.append('page_slug', slug);


        apiServices.getaddnewpagesrequest(formData).then(res => {
            if (res.data.status === "success") {
                toast.success(res?.data?.message);
                window.location.href='/csadmin/all-pages'
                setpageContent('')
                reset();
            }
            else if (res.data.status === "error") {

                toast.error(res?.data?.message);
            }
        }).catch(error => {
            console.error('Error submitting form:', error);
            // Handle error
        });
    };





    const getPagedetail = () => {
        const dataString = {
            page_slug: slug
        }
        apiServices.getnewpagedetailrequest(dataString).then((res) => {
            if (res.data.status == 'success') {
                const data = res.data.PageDetail

                setValue("page_title", data?.page_title);
                setValue("page_url", data?.page_url);
                setValue("page_meta_description", data?.page_meta_description);
                setValue("page_meta_keyword", data?.page_meta_keyword);
                setValue("page_meta_title", data?.page_meta_title);
                setshowbreadcrumb(data.page_show_breadcrumbs)
                setshowtitle(data.page_show_title)
                if (data.page_content !== undefined && data?.page_content !== null) {
                    setpageContent(data?.page_content);
                }
                if (data?.page_header_img !== undefined && data?.page_header_img !== null) {
                    setHeaderImage(data?.page_header_img)

                }


            }
        }).catch((error) => { })
    }



    useEffect(() => {
        const loadScript = (src, callback) => {
            const script = document.createElement('script');
            script.src = src;
            script.onload = callback;
            document.body.appendChild(script);
        };

        const initializeCKEditor = () => {
            const editorElement = document.getElementById('editor');
            if (editorElement && !editorRef.current) {
                window.CKEDITOR.replace(editorElement);
                editorRef.current = window.CKEDITOR.instances[editorElement.id];

                editorRef.current.on('change', () => {
                    const data = editorRef.current.getData();
                    setpageContent(data);
                });
                setIsEditorReady(true);
            }
        };

        loadScript('https://cdn.ckeditor.com/4.12.1/full-all/ckeditor.js', () => {
            const checkCKEditorLoaded = setInterval(() => {
                if (window.CKEDITOR) {
                    clearInterval(checkCKEditorLoaded);
                    initializeCKEditor();
                }
            }, 100);

            return () => clearInterval(checkCKEditorLoaded);
        });

        return () => {
            if (editorRef.current) {
                editorRef.current.destroy();
                editorRef.current = null;
            }
        };
    }, []);

    useEffect(() => {
        if (isEditorReady) {
            if (editorRef.current && pageContent) {
                editorRef.current.setData(pageContent);

            }
        }
    }, [isEditorReady, pageContent]);


    const onKeyUpPageName = () => {
        const pageName = getValues('page_title')
        const pageSlug = pageName
            .toLowerCase()
            .trim()
            .replace(/\s+/g, '-')
            .replace(/[^a-z0-9\-]/g, '');
        setValue('page_url', pageSlug);
        setValue('page_meta_title', pageName);
    }


    const [image, setImage] = useState({ preview: "", raw: "" });
    const handleImage = e => {
        const file = e.target.files[0];
        if (!file.type.match(/image.*/)) {
            alert("Invalid Image Format, supported formats are png | jpg | jpeg | webp.");
            return;
        }
        if (e.target.files.length) {
            const reader = new FileReader();
            reader.onload = function (event) {
                setHeaderImage(e.target.files[0])
                setImage({
                    preview: URL.createObjectURL(e.target.files[0]),
                    raw: event.target.result
                });
            };
            reader.readAsDataURL(e.target.files[0]);
        }
    };


    return (<>
        <ToastContainer />
        <div id="layout-wrapper">
            <AdminHeader />
            <Sidebar />
            <div class="main-content">
                <div class="page-content">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-12">
                                <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 class="mb-sm-0">Add New</h4>
                                    <div class="page-title-right">
                                        <ol class="breadcrumb m-0">
                                            <li class="breadcrumb-item"><a href="/csadmin/dashboard">Dashboard</a></li>
                                            <li class="breadcrumb-item active">Add New Page</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            {error && error.type == 'emptycategory' ? <Alert variant={'danger'}>
                                {error.message}
                            </Alert> : ""}

                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="card">
                                        <div class="card-header align-items-center d-flex">
                                            <h4 class="card-title mb-0 flex-grow-1">Add New Page</h4>
                                        </div>
                                        <div class="card-body">
                                            <div class="live-preview">
                                                <div class="row gy-4">
                                                    <div class="col-lg-12 col-xxl-12">
                                                        <div>
                                                            <label for="basiInput" class="form-label" >Page Name<span>*</span></label>
                                                            <input type="text" class="form-control" name='page_title'  {...register("page_title", { required: true, })} onKeyUp={onKeyUpPageName} />
                                                            {errors.page_title && errors.page_title.type === "required" && (
                                                                <small className="text-danger">Page Name is required.</small>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div class="col-xxl-12 col-md-12">
                                                        <div>
                                                            <label for="basic-url" class="form-label">Page URL</label>
                                                            <div class="input-group">
                                                                <span class="input-group-text" id="basic-addon3">https://proptrail.au/</span>
                                                                <input type="text" class="form-control" id="basic-url" aria-describedby="basic-addon3" name='page_url' {...register("page_url", { required: true, })} />
                                                                {errors.page_url && errors.page_url.type === "required" && (
                                                                    <small className="text-danger">Page Url is required.</small>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-xxl-12 col-md-12">
                                                        <div>
                                                            <label for="basiInput" class="form-label">Page Content</label>
                                                            <textarea className="ckeditor-classic" id="editor"></textarea>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* section 1 end */}
                                    <div class="card">
                                        <div class="card-header align-items-center d-flex">
                                            <h4 class="card-title mb-0 flex-grow-1">Page Header Image:</h4>
                                        </div>
                                        <div class="card-body">
                                            <div class="live-preview">
                                                <div class="row gy-4">
                                                    <div class="col-lg-12">
                                                        <div style={{ display: 'flex' }}>

                                                            <div style={{ width: '50px', height: '50px', borderRadius: '3px', border: '1px solid lightgray' }}>

                                                                {image.preview ? <>
                                                                    <img src={image.preview} style={{ width: '100%', height: '100%' }}></img>
                                                                </> : <> <img src={HeaderImage !== '' ? constant.Image_Base_Url + HeaderImage : constant.default_img} style={{ width: '100%', height: '100%' }}></img></>}
                                                            </div>

                                                            <input
                                                                class="form-control"
                                                                type="file"
                                                                // onChange={(e) => setHeaderImage(e.target.files[0])}
                                                                onChange={(e) => { handleImage(e) }}
                                                                accept=".jpeg,.jpg,.png"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div class="form-check form-switch mb-2">
                                                            <input class="form-check-input" type="checkbox" role="switch" id="showtitle" onChange={(e) => { setshowtitle(e.target.checked == true ? 1 : 0) }} checked={showtitle == 1 ? true : false} />
                                                            <label class="form-check-label" for="flexSwitchCheckDefault" name='show_title' >Show Title</label>
                                                        </div>
                                                        <div class="form-check form-switch mb-2">
                                                            <input class="form-check-input" type="checkbox" role="switch" id="showbreadcrumb" onChange={(e) => { setshowbreadcrumb(e.target.checked == true ? 1 : 0) }} checked={showbreadcrumb == 1 ? true : false} />
                                                            <label class="form-check-label" for="flexSwitchCheckDefault" name='show_breadcrumb'>Show BreadCrumb</label>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* section 2 end */}
                                    <div class="card">
                                        <div class="card-header align-items-center d-flex">
                                            <h4 class="card-title mb-0 flex-grow-1">SEO - Meta Tags</h4>
                                        </div>
                                        <div class="card-body">
                                            <div class="live-preview">
                                                <div class="row gy-4">

                                                    <div class="col-xxl-12 col-md-12">
                                                        <div>
                                                            <label for="basiInput" class="form-label" >Meta Title<span className="text-danger">*</span></label>
                                                            <input type="text" class="form-control" name='page_meta_title'  {...register("page_meta_title", {
                                                                // required: true,

                                                            })} />
                                                            <p style={{ fontSize: '12px' }}>Max length 70 characters</p>
                                                            {/* {errors.meta_title && errors.meta_title.type === "required" && (
                                                                <small className="text-danger">Meta Title is required.</small>
                                                            )} */}
                                                            {error && error.type == 'page_meta_title' && (
                                                                <small className="text-danger">{error.message}</small>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div class="col-xxl-12 col-md-12">
                                                        <div>
                                                            <label for="basiInput" class="form-label" >Meta Keyword</label>
                                                            <input type="text" class="form-control" name='page_meta_keyword'  {...register("page_meta_keyword", {})} />
                                                            <p style={{ fontSize: '12px' }}>Max length 160 characters</p>
                                                            {error && error.type == 'page_meta_keyword' && (
                                                                <small className="text-danger">{error.message}</small>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div class="col-xxl-12 col-md-12">
                                                        <div>
                                                            <label for="basiInput" class="form-label" >Meta Description</label>
                                                            <input type="text" class="form-control" name='page_meta_description'  {...register("page_meta_description", {})} />
                                                            <p style={{ fontSize: '12px' }}>Max length 250 characters</p>
                                                            {error && error.type == 'page_meta_description' && (
                                                                <small className="text-danger">{error.message}</small>
                                                            )}
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-footer text-end">
                                            <div class="text-end">
                                                <button type="submit" class="btn btn-primary">Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div >
        </div >
    </>)
}
export default AddNewPages